
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');

.about-page-container {
    padding: 56px 0;

    @media (max-width: 450px) {
      // margin-top: 654px;
    }

    .about-us-conatiner-first {

      width: 100%;

      @media (max-width:450px) {
        width: 100%;
      }


      .about-us-heading {
        font-weight: 500;
        font-size: 24px;
        line-height: 49px;
        text-shadow: 2px 5px 40px rgba(0, 0, 0, 0.08);
        color: #0184FF;
      }

      .about-us-subheading {
        font-weight: 700;
        font-size: 51px;
        line-height: 62px;
        color: #000000;

        @media (max-width:450px) {
          font-size: 23px;
          line-height: 30px;
        }
      }
    }

    .about-us-conatiner-second {
      display: flex;
      justify-content: space-between;
      padding-top: 42px;
      width: 100%;

      @media (max-width:768px) {
        flex-direction: column;
        gap: 10px;
      }

      .about-us-main-content-contaiiner {
        width: 53%;

        @media (max-width:768px) {
          width: 100%;
        }

        .about-us-content {
          font-weight: 400;
          font-size: 16px;
          line-height: 31px;
          width: 100%;
          padding: 0 0 0 72px;

          a {
            color: #0184FF;
          }

          @media (max-width:768px) {
            font-size: 16px;
            line-height: 22px;
            padding: 0;
          }
        }
      }

    }
  }
.about-us-container2-second{
    margin-top: 96px;
    width: 100%;
    display: flex;
    gap: 45px;
    @media screen and (max-width: 800px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.about-us-card-second{
    text-align: center;
    width: 25%;
    @media screen and (max-width: 800px) {
        width: 40%;
        text-align: center;
    }
    @media screen and (max-width: 600px) {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

}
.about-image-container2{
    border-radius: 138px;
    margin-bottom: 24px;

}

.about-us-name{
    font-size: 24px;
    font-weight: 500;
    line-height: 29.26px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 8px;
}
.about-us-tittle{
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    margin-bottom: 16px;
    color: #797979;
    font-family: 'Montserrat', sans-serif;
}
.about-us-team-detail{
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #797979;
    font-family: 'Montserrat', sans-serif;
}


.about-us-container3-second{
    display: flex;
    gap: 66px;
    margin-top: 72px;
    width: 100%;
    @media screen and (max-width: 800px) {
        flex-wrap: wrap;
    
    }
}
.about-image-container3{
    width: 50%;
    border-radius: 24px;
    @media screen and (max-width: 800px) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
}
.about-us-main-content-container3{
    width: 50%;
    @media screen and (max-width: 800px) {
        width: 100%;
    
    }
}

.about-us-image{
  max-width: 100%;
}
.about-us-image1{
    max-width: 100%;
    display: block;
    border-radius: 50%;
}
.content-first{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 32px;
    margin-bottom: 37px;
}
.about-us-logo{
    width: 96px;
    height: 96px;
    padding: 16px;
    border-radius: 50%;
    background-color: rgba(1, 132, 255, 0.1);
    .img{
        width: 66px;
        height: 66px;
    }
}
.heading-blue{
    font-size: 24px;
    font-weight: 500;
    line-height: 29.26px;
    color: #0184FF;
    margin-bottom: 22px;
    font-family: 'Montserrat', sans-serif;
}
.about-us-content-container3{
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
}
