@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,700&display=swap');


.thankyou-wrap {
  background: #E9F0F6;
  background-image: url("./thankyou.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  @media (max-width:768px) {
    background-position: center;
  }
}

.brandneed-logo {
  a {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    text-decoration: none;
    padding: 1rem 0;
    cursor: pointer;

    .logo-pic {
      text-align: center;
      align-items: center;
      width: 56px;
    }

    .logo-text {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #202020;
    }
  }
}

.thankyou-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
height: 80vh;
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 39px;
    text-align: center;
    color: #202020;
    @media (max-width:600px) {
      font-size:16px;
      line-height: 26px;
    }
    &:first-child{
      
padding-top: 24px;
    }
  }
}

.goback {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: inline-block;
    margin-top: 1rem;
    background: #0184FF;
    border-radius: 12px;
    padding: 16px 32px;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    display: inline-block;
  }
}