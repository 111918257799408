// @import '../../assets/Style/base';

.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 64px 20px;

  .heading {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: #202020;
    margin-top: 36px;
  }

  .desc {
    line-height: 24px;
    width: 394px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: rgba(32, 32, 32, 0.8);
    margin-top: 16px;

    @media screen and (max-width: 424px) {
      width: auto;
    }
  }

  .go-home-btn {
    cursor: pointer;
    padding: 12px 80px;
    background: #0C6BFF;
    border-radius: 8px;
    border: none;
    margin-top: 40px;

    a {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;
      text-decoration: none;
    }
  }

  .error-img-wrap {
    max-height: 240px;
    width: auto;

    .error-img {
      display: block;
      max-width: 100%;
      min-width: 100%;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}