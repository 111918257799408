      @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,700&display=swap');

      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
      }

      .contact-links {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        position: absolute;
        left: 7%;
        top: 60%;
        z-index: 1;

        @media (max-width:450px) {
          display: none;

        }

        .folow-us-logo-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;

          a {
            cursor: pointer;

            img {
              height: 16px;
            }
          }
        }



        .follow-us-text-container {
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #000000;
          writing-mode: vertical-rl;
        }

        .follow-us-divider {
          width: 0.01rem;
          height: 50px;
          background: #202020;
          align-items: center;


        }
      }

      .section-hero {
        text-align: center;
        width: 100%;
        position: relative;
        padding-top: 56px;

        @media (max-width:768px) {
          padding-top: 48px;
        }





        .digital-agency {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 34px;
          color: #0184FF;
          text-shadow: 2px 5px 40px rgba(0, 0, 0, 0.08);

          @media (max-width:768px) {
            font-size: 16px;
            line-height: 22px;
          }
        }

        .brandneed-large-text {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 64px;
          line-height: 84px;
          text-align: center;
          color: #202020;
          position: relative;
          display: inline-block;

          @media (max-width:768px) {
            font-size: 32px;
            line-height: 42px;
          }

          .artwork-1 {
            position: absolute;
            top: 10%;
            left: 5%;
            display: inline-flex;

            img {
              width: 47px;
              height: 56px;
            }

            @media (max-width:768px) {
              display: none;
            }
          }

          .artwork-2 {
            position: absolute;
            top: -15%;
            right: 5%;
            display: inline-flex;

            img {
              width: 47px;
              height: 56px;
            }

            @media (max-width:768px) {
              display: none;
            }
          }
        }

        .brandneed-top-service-container {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          color: #202020;
          margin-top: 16px;
          padding: 0 13rem;

          @media (max-width:768px) {
            margin-top: 20px;
            font-size: 21px;
            padding: 0;
          }

        }

        .request-btn {
          padding: 18px 41px 19px 42px;
          border-radius: 12px;
          border: 1px solid #0184FF;
          background: #0184FF;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 27px;
          color: #FFFFFF;
          margin-top: 40px;
          box-shadow: 0px 2px 25px rgba(1, 132, 255, 0.5);
          cursor: pointer;

          @media (max-width:992px) {
            padding: 17px 29px 17px 29px;
          }

          @media (max-width:450px) {
            padding: 10px 20px 10px 20px;
            font-size: 15px;
            margin-top: 26px;

          }
        }

      }

      .grow-conatiner {
        margin-top: 160px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 31px;
        color: rgba(0, 0, 0, 0.6);

        @media (max-width:1240px) {
          margin-top: 135px;
        }

        @media (max-width:992px) {
          margin-top: 120px;
        }

        @media (max-width:450px) {
          margin-top: 38px;
          font-size: 15px;
        }

      }


      .partner-container {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        gap: 60px;
        width: 100%;

        @media (max-width:768px) {
          flex-wrap: wrap;
          gap: 10px;
          margin-top: 15px;

        }
      }






      .brandneed-functionality-container {
        display: flex;
        padding: 110px 0px 0 0;
        width: 100%;
        justify-content: space-between;

        @media (max-width:768px) {
          display: block;
          padding: 35px 13px 0 13px;

        }


        .left-brandneed-functionality {
          background: rgb(255, 255, 255);
          // background: radial-gradient(circle, rgba(0, 255, 255, 0.2) 3%, rgba(201, 185, 210, 0.1) 68%, rgba(200, 179, 212, 0) 98%);
          // backdrop-filter: blur(160px);
          background-image: url('../Assets/Images/reachedbg.png');
          display: grid;
          border-radius: 15px;
          grid-template-columns: 1fr 1fr;
          gap: 25px;
          width: 44%;
          border-color: transparent;
          background-size: 70%;
          background-repeat: no-repeat;
          background-position: center;

          @media (max-width:768px) {
            grid-template-columns: 1fr;
            width: 100%;

          }

          .functional-section {

            .shape_background {
              display: block;
              border-radius: 11px;
              background-color: #FFFFFF;
              -webkit-transform: skewY(-2.5deg);
              transform: skewY(-2.5deg);
              color: #000000;
              padding: 42px 38px 0px 30px;
              transition: .6s ease;
              box-shadow: 2px 5px 40px rgba(0, 0, 0, 0.08);

              &:hover {
                background-color: #0184FF;
                color: #FFFFFF;
                cursor: pointer;

                .functionality-image-logo1 {
                  background-color: #FFFFFF !important;
                }

                .functionality-image-logo2 {
                  background-color: #FFFFFF !important;
                }

                .functionality-image-logo3 {
                  background-color: #FFFFFF !important;
                }

                .functionality-image-logo4 {
                  background-color: #FFFFFF !important;
                }

                .seo-heading {
                  color: #fff;
                }

                .seo-heading-info {
                  color: #FFFFFF;
                }



              }



              .functionality-image {
                text-align: end;


                @media (max-width:450px) {
                  padding: 15px 30px 0 0;
                }

                .functionality-icon {
                  width: 100%;
                  height: 64px;
                  display: block;
                  cursor: pointer;
                  margin-left: 70px;

                  @media (max-width:992px) {
                    margin-left: 43px;
                  }
                }

                .functionality-image-logo1 {
                  -webkit-mask: url("../Assets/Images/seo-icon.svg") no-repeat center;
                  mask-image: url("../Assets/Images/seo-icon.svg") no-repeat center;
                  background-color: rgb(35, 138, 9);

                }


                .functionality-image-logo2 {
                  -webkit-mask: url("../Assets/Images/logo-icon.svg") no-repeat center;
                  mask-image: url("../Assets/Images/logo-icon.svg") no-repeat center;
                  background-color: #B71FFF;
                }

                .functionality-image-logo3 {
                  -webkit-mask: url("../Assets/Images/ads-icon.svg") no-repeat center;
                  mask-image: url("../Assets/Images/ads-icon.svg") no-repeat center;
                  background-color: #0B31BC;
                }

                .functionality-image-logo4 {
                  -webkit-mask: url("../Assets/Images/social-icon.svg") no-repeat center;
                  mask-image: url("../Assets/Images/social-icon.svg") no-repeat center;
                  background-color: #E6289D;

                }

              }

              .content-of-functionality {
                // display: inline-block;
                -webkit-transform: skewY(0deg);
                transform: skewY(2.5deg);


                .optimize-content {
                  font-size: 12px;
                  font-weight: 500;
                  font-family: 'Montserrat';
                  font-style: normal;
                  color: #000000;
                  padding-bottom: 32px;

                  @media (max-width:992px) {
                    padding-bottom: 24px;
                  }

                  .seo-heading {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 49px;

                    @media (max-width:450px) {
                      font-size: 27px;
                    }
                  }

                  .seo-heading-info {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;

                    @media (max-width:450px) {
                      font-size: 17px;
                      line-height: 22px;

                    }
                  }

                }

              }

            }
          }

        }
      }



      .right-brandneed-functionality {
        width: 54%;
        padding: 0 0 0 100px;

        @media (max-width:768px) {
          padding: 0;
          width: 100%;
          margin-top: 40px;
        }

        .brandnee-ques-container {
          font-weight: 500;
          font-size: 24px;
          line-height: 49px;
          color: #0184FF;
          padding-bottom: 5px;

        }

        .brandneed-online-solution {
          font-weight: 700;
          font-size: 51px;
          line-height: 71px;

          @media (max-width:768px) {
            font-size: 32px;
            line-height: 42px;
          }

        }

        .seo-expert {
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          color: #000000;
          padding-top: 20px;

          @media (max-width:1240px) {
            font-size: 15px;
            padding-top: 12px;

          }

          @media (max-width:450px) {
            padding-top: 8px;
            font-size: 14px;
          }

        }

        .main-development-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 29px;
          grid-row-gap: 19px;
          padding-top: 39px;

          @media (max-width:768px) {
            padding-top: 23px;
            grid-template-columns: 1fr;
          }

          .development-container {
            border: 1px solid #DCEDFC4D;
            background: #DCEDFC4D;
            padding: 10px 23px 10px 14px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;

            i {
              display: flex;
            }

            @media (max-width:450px) {
              gap: 5px;
              padding: 10px 10px 8px 10px;
              font-size: 13px;
            }
          }
        }

      }





      .brandneed-container {


        .boosting-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding-top: 128px;
          position: relative;

          @media (max-width:768px) {
            display: block;
            padding-top: 13px;

          }

          .cross-arrow-image {
            position: absolute;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 3%;
            transform: rotate(5deg);
            left: -6%;

            @media (max-width:768px) {
              display: none;

            }

            img {
              width: 180px;
            }
          }

          // .cross-arrow-container {
          //   width: 235px;
          //   height: 235px;
          //   position: absolute;
          //   top: 7%;
          //   left: 42%;

          //   @media (max-width:1240px) {
          //     top: 5%;
          //     left: 40%;
          //   }

          //   @media (max-width:450px) {
          //     display: none;
          //   }

          //   .cross-arrow-image {
          //     background: url("../Assets/Images/cross-arrow.png");
          //     width: 100%;
          //     height: 100%;

          //   }
          // }


          .boosting-container-left {
            // padding: 74px;
            width: 50%;


            @media (max-width:768px) {
              width: 100%;
              padding: 14px;

            }


            .boosting-heading {
              font-weight: 500;
              font-size: 24px;
              line-height: 49px;
              color: #0184FF;
              text-shadow: 2px 5px 40px rgba(0, 0, 0, 0.08);
            }

            .boost-conversion-container {
              font-weight: 700;
              font-size: 51px;
              line-height: 71px;
              color: #000000;

              @media (max-width:992px) {
                font-size: 32px;
                line-height: 42px;
              }


              .boost-conversion-rate-heading {
                color: #0184FF;
              }

            }

            .change-container {
              font-weight: 400;
              font-size: 24px;
              line-height: 28px;
              color: #000000;
              padding-top: 18px;

              @media (max-width:992px) {
                font-size: 19px;

              }

            }


            .performance-increasing-container {
              display: flex;
              gap: 40px;
              padding-top: 42px;
              align-items: center;

              @media (max-width:768px) {
                display: grid;
                align-items: flex-start;
                gap: 10px;
              }

              .increasing-calculation {
                font-size: 56px;
                font-weight: 700;
                color: #0184FF;

                @media (max-width:992px) {
                  font-size: 47px;
                }
              }

              .increase-content {
                font-size: 16px;
                font-weight: 500;

                @media (max-width:450px) {
                  font-size: 11px;
                }

              }

              .increase-traffic-container-divider {
                width: 1px;
                height: 100px;
                filter: blur(2px);
                border: 1px solid #1A3FDE;

                @media (max-width:768px) {
                  width: 100px;
                  height: 1px;
                }
              }
            }


            .consultation-btn {
              padding: 10px 24px 10px 24px;
              background-color: #14A032;
              color: #FFFFFF;
              border: 1px solid #14A032;
              border-radius: 7px;
              margin-top: 62px;
              font-weight: 700;
              font-size: 22px;
              line-height: 27px;
              cursor: pointer;
            }
          }

          .boosting-container-right {
            position: relative;
            width: 50%;

            @media (max-width:768px) {
              width: 100%;


            }


            .women-image-container {
              width: 100%;
              height: 826px;
              position: relative;

              @media (max-width:768px) {}

              .women-image {
                background: url("../Assets/Images/women-1.png") no-repeat center;
                width: 100%;
                height: 100%;
                max-width: 100%;
                position: relative;

                .boost-art-1 {
                  position: absolute;
                  left: 100px;
                  top: 100px;
                  width: 45px;

                  @media (max-width:768px) {
                    display: none;
                  }
                }

                .boost-art-2 {
                  position: absolute;
                  right: 85px;
                  width: 105px;

                  @media (max-width:768px) {
                    display: none;
                  }
                }
              }

              .ads-analytics-container {
                border: 1px solid #ffff;
                width: 219px;
                height: 117px;
                text-align: center;
                align-items: center;
                padding: 16px 27px 24px 27px;
                border-radius: 12px;
                background-color: #FFFFFF;
                position: absolute;
                top: 50%;
                left: 58%;
                box-shadow: 10px 11px 25px 2px rgba(0, 0, 0, 0.11);

                @media (max-width:768px) {
                  top: 50%;
                  right: 0;
                  left: auto;
                }

                .ads-heading {
                  font-weight: 700;
                  font-size: 17px;
                  line-height: 21px;
                  padding: 0 39px 17px 0;
                  color: #000000;

                  @media (max-width:768px) {
                    padding: 0 0px 4px 0;
                    font-size: 14px;

                  }

                }

                .ads-image-container {
                  display: flex;
                  justify-content: space-between;

                  .meter2-image-divider {
                    width: 0;
                    height: 40px;
                    border: 1px solid #8F8F8F;
                    filter: blur(1px);
                  }

                  .meter-2-grow-percentage {
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 37px;
                    color: #555555;
                    display: flex;
                    gap: 7.5px;
                    align-items: center;

                    @media (max-width:450px) {
                      font-size: 28px;
                    }

                    .ads-analytics-arrow {
                      width: 0;
                      height: 0;
                      border-top: 0;
                      border: 5px solid transparent;
                      border-bottom: 5px solid red;
                      margin-bottom: 8px;
                    }
                  }
                }
              }

              .meter-container {
                width: 147px;
                height: 184px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border: 1px solid black;
                align-items: center;
                position: absolute;
                bottom: 24.5%;
                left: 12%;
                border: 1px solid #ffff;
                background: #ffff;
                border-radius: 12px;
                box-shadow: 10px 11px 25px 2px rgba(0, 0, 0, 0.11);

                @media (max-width:768px) {
                  width: 123px;
                  height: 154px;
                  bottom: 5%;
                  left: 0;

                }

                .meter-image-divider {
                  height: 0px;
                  width: 99px;
                  border: 1px solid #8F8F8F;
                  filter: blur(2px);
                }

                .grow-business {
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 20px;
                  text-align: center;
                  color: #000000;
                  padding: 16px;

                  @media (max-width:450px) {
                    padding: 8px;
                    font-size: 14px;

                  }
                }

                .meter-image-first {
                  width: 60%;
                  padding: 16px 0 16px 0;

                }
              }



            }

          }


        }
      }


      .social-media-management-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 24px 0;
        position: relative;
        @media (max-width:768px) {
          display: block;

        }
        .white-smoke-container {
          z-index: 100;


          .white-smoke {
            width: 100%;
            height: 143px;


          }
        }

        .smoke-container2 {
          margin-top: -138px;
          width: 106%;

          .white-smoke-2 {
            width: 100%;
            height: 143px;

            @media (max-width:450px) {
              display: none;

            }
          }
        }

        .curved-arrow-image {
          position: absolute;
          top: -100px;
          left: -100px;

          @media (max-width:768px) {
            display: none;

          }

          img {
            width: 50%;
          }
        }

        .social-media-management-left {
          width: 46%;
          margin-left: 16px;
          padding-top: 14px;
          position: relative;
@media (max-width:768px) {
              width: 100%;
            }
          .rotate-arrow-image {
            position: absolute;
            right: -100px;
            bottom: 30px;

            @media (max-width:768px) {
              display: none;
            }

            img {
              width: 150px;
            }
          }

          @media (max-width:450px) {
            width: 100%;
            margin-left: 0;
            padding-top: 0;

          }

          .social-management-heading {
            font-weight: 500;
            font-size: 24px;
            line-height: 49px;
            color: #0184FF;
            text-shadow: 2px 5px 40px rgba(0, 0, 0, 0.08);

            @media (max-width:450px) {
              font-size: 19px;
            }
          }

          .maximize-visibility-container {
            font-weight: 700;
            font-size: 51px;
            line-height: 71px;
            color: #000000;

            @media (max-width:450px) {
              font-size: 41px;

            }

            .maximize-heading {
              color: #14A032;
            }
          }

          .social-media-management-article-container {
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            color: #202020;
            padding-top: 24px;

            @media (max-width:450px) {
              font-size: 20px;
              padding-top: 5px;
            }
          }

          .orgainc-traffic-conatiner {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: #202020;
            padding-top: 60px;

            @media (max-width:768px) {
              font-size: 18px;
              padding-top: 25px;

            }

            .orgainc-like-container-first {
              display: flex;
              justify-content: flex-start;
              gap: 24px;
              padding-bottom: 18px;

              @media (max-width:768px) {
                flex-direction: column;
                gap: 8px;
              }

              img {
                width: 40px;

                @media (max-width:768px) {
                  width: 24px;
                }
              }

              p {
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                color: #202020;

                @media (max-width:768px) {
                  font-size: 16px;
                  line-height: 22px;
                }
              }
            }

            .orgainc-like-container-second {
              display: flex;
              justify-content: flex-start;
              gap: 24px;

              @media (max-width:768px) {
                flex-direction: column;
                gap: 8px;
              }

              img {
                width: 40px;
                height: 40px;

                @media (max-width:768px) {
                  width: 24px;
                  height: 24px;
                }
              }

              p {
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                color: #202020;

                @media (max-width:768px) {
                  font-size: 16px;
                  line-height: 22px;
                }
              }
            }
          }

          .maximize-button {
            padding: 10px 37px 10px 38px;
            background: #0184FF;
            border-radius: 7px;
            border: 1px solid #0184FF;
            margin-top: 50px;
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            color: #FFFFFF;

          }

        }

        .social-media-management-right {
          width: 54%;
          margin-top: -24px;
          z-index: -1;
          max-height: 800px;
          overflow: hidden;
          background-color: #FFFFFF;
          box-shadow: 0 0 5px 10px #FFFFFF;
          position: relative;

          .smoke-top {
            position: absolute;
            z-index: 1;
            top: -40px;
            left: -50px;
            height: 80px;
            width: 100%;
          }

          .smoke-bottom {
            position: absolute;
            z-index: 1;
            bottom: -40px;
            left: 0;
            height: 80px;
            width: 100%;
          }

          // &::after{
          //   content:'';
          //   width:100%;
          //   height:100%;    
          //   position:absolute;
          //   left:0;
          //   bottom:0;
          //   background:url("../Assets/Images/whitesmoke.png");
          //   background-repeat: no-repeat;
          // }
          // &::before{
          //   content:'';
          //   width:100%;
          //   height:100%;    
          //   position:absolute;
          //   left:0;
          //   top:0;
          //   background:url("../Assets/Images/whitesmoke.png");
          //   background-repeat: no-repeat;
          // }
          @media (max-width:768px) {
            display: none;

          }

          .animation-image {
            transform: rotate(-15deg);
            margin-left: 235px;

            img {
              width: 100%;
              animation: move 25s linear infinite;

            }

            @keyframes move {
              0% {
                transform: translate3d(0, 0, 0);
              }

              100% {
                transform: translate3d(0, -1500px, 0);
                /* The image width */
              }
            }

            // max-width: 100%;
            // height: 600px;
            // overflow: hidden;
            // img{
            //   width: 100%;
            //   animation-name: move;
            //   -webkit-animation-name: move;
            //   animation-duration: 14s;
            //   -webkit-animation-duration: 14s;
            //   animation-iteration-count: infinite;
            //   -webkit-animation-iteration-count: infinite;
            //   animation-direction: right;
            //   -webkit-animation-direction: right;
            //   -webkit-animation-timing-function: linear;
            //   animation-timing-function: linear;
            //   transform: rotate(-5deg);
            // }
            // @keyframes move {
            //   0% {
            //     margin-top: -400px;
            //   }
            //   100% {
            //     margin-bottom: 800px;
            //   }
            // }
          }

          // .animation-container-second {
          //   display: flex;
          //   padding-left: 139px;

          //   .animation-image {
          //     max-width: 123%;
          //   }
          // }
        }

      }



      .ads-management-section {
        margin-top: -110px;

        @media (max-width:768px) {
          margin-top: 0;
        }

        .ads-management-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          overflow: hidden;
          padding: 72px 0 24px 0;
          position: relative;

          @media (max-width:768px) {
            display: block;
          }

          .ads-management-left-container {
            width: 50%;

            @media (max-width:768px) {
              width: 100%;
            }


            .ads-image-container {
              width: 100%;

              .ads-management-image {
                max-width: 100%;
              }
            }
          }

          .ads-management-right-conatiner {
            width: 50%;
            padding: 36px 0 0 63px;

            @media (max-width:768px) {
              width: 100%;
              padding: 10px 0 0 13px;

            }

            .ads-management-heading {
              font-weight: 500;
              font-size: 24px;
              line-height: 49px;
              color: #0184FF;
              text-shadow: 2px 5px 40px rgba(0, 0, 0, 0.08);

              @media (max-width:450px) {
                font-size: 22px;
                line-height: 25px;

              }
            }

            .reach-heading {
              font-weight: 700;
              font-size: 51px;
              line-height: 71px;

              @media (max-width:768px) {
                font-size: 32px;
                line-height: 42px;
                margin-top: 20px;
              }

              .reimagined {
                color: #0184FF;

                .ads-management-arrow-right {
                  img {
                    width: 48px;
                  }
                }
              }
            }

            .target-audience-container {
              font-weight: 400;
              font-size: 24px;
              line-height: 32px;
              padding-top: 24px;

              @media (max-width:450px) {
                font-size: 21px;
                padding-top: 10px;

              }
            }

            .spend-less-heading {
              font-weight: 500;
              font-size: 34px;
              line-height: 44px;
              color: #202020;
              margin-top: 24px;

              @media (max-width:450px) {
                font-weight: 600;
                font-size: 29px;
                margin-top: 21px;
              }
            }

            .social-connect-container {
              margin-top: 24px;
              border: 1px solid rgba(0, 165, 255, 0.05);
              width: 58%;
              text-align: center;
              background: #F2FAFF;
              box-shadow: 6px 6px 10px 1px rgba(0, 0, 0, 0.1);
              border-radius: 12px;
              padding: 16px 24px;

              @media (max-width:450px) {
                margin-top: 15px;
              }

              .advertising-platform-heading {
                text-align: start;
                // padding: 16px 0 13px 24px;
                margin-bottom: 16px;
                font-weight: 700;
                font-size: 15px;
                line-height: 18px;
                color: #000000;

                @media (max-width:450px) {
                  text-align: center;
                  padding: 7px 0 7px 0px;
                  font-size: 11px;
                }
              }

              .social-connect-image {
                padding: 0 0 10px 5px;
                max-width: 100%;
              }
            }
          }
        }

      }


      .web-builder-section {
        overflow: hidden;
        margin-top: -110px;
      }

      .logo-and-website-builder-main-container {

        margin: 96px 0 24px 0;

        @media (max-width:450px) {
          padding-left: 14px;
        }

        .website-builder-main-container {
          width: 100%;
          display: flex;
          justify-content: space-between;



          @media (max-width:768px) {
            display: block;
          }

          .left-logo-builder-container {
            width: 50%;
            text-align: start;
            position: relative;

            .RotateArrow {
              position: absolute;
              right: 0;
              bottom: 88px;

              img {
                width: 120px;
              }
              @media (max-width:768px) {
                display: none;
              }
            }

            @media (max-width:768px) {
              width: 100%;
              padding-left: 0;
            }

            .logo-and-website-heading {
              color: #1A3FDE;
              text-shadow: 2px 5px 40px rgba(0, 0, 0, 0.08);
              font-weight: 500;
              font-size: 24px;
              line-height: 49px;

              @media (max-width:450px) {
                font-size: 21px;
              }
            }

            .way-change-heading {
              font-weight: 700;
              font-size: 51px;
              line-height: 76px;
              color: #202020;

              @media (max-width:768px) {
                font-size: 32px;
                line-height: 42px;
              }

              .build-heading {
                border: 3px solid #68C3D4;
                color: #0184FF;
                padding: 3px 13px 3px 13px;
                position: relative;

                @media (max-width:768px) {
                  padding: 3px;
                  display: inline-flex;
                }

                &::after,
                &::before {
                  content: "";
                  position: absolute;
                  height: 112%;
                  width: 20px;
                  top: -5px;
                  background-image: radial-gradient(circle at center, #68C3D4 5px, transparent 5px), radial-gradient(circle at center, #68C3D4 5px, transparent 5px);
                  background-size: 20px 10px;
                  background-position: top center, bottom center;
                  background-repeat: no-repeat;
                }

                &::before {
                  left: -12px;
                }

                &::after {
                  right: -12px;
                }

                .cursor-icon {
                  position: absolute;
                  bottom: -30px;
                  left: 100px;

                  img {
                    width: 18px;
                  }
                }
              }
            }

            .decades-goes-for-website-builder-container {
              font-weight: 400;
              font-size: 24px;
              line-height: 34px;
              padding-top: 16px;
              width: 75%;

              @media (max-width:768px) {
                width: 100%;
                padding-top: 15px;
                font-size: 16px;
              }

            }

            .website-builder-button {
              margin-top: 36px;

              a {
                color: #fff;
                border: 1px solid #14A032;
                padding: 10.28px 24px 9.72px 24px;
                background-color: #14A032;
                border-radius: 7px;
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                cursor: pointer;
                text-decoration: none;
              }
            }

            .features-container-website-builder {
              display: flex;
              gap: 71px;
              padding-top: 41px;

              @media (max-width:450px) {
                gap: 19px;
                padding-top: 30px;
              }

              .features-container-heading {
                font-weight: 700;
                font-size: 32px;
                line-height: 39px;
                color: #0184FF;
              }

              .features-container-co-heading {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: rgba(0, 0, 0, 0.8);
              }

            }
          }

          .right-logo-builder-container {
            width: 50%;

            @media (max-width:768px) {
              width: 100%;
            }

            .desktop-container {
              position: relative;

              .image-desktop {
                width: 100%;
              }

              .web-builder-image-bg {
                min-height: 400px;
                position: relative;

                .image-desktop {
                  width: 750px;
                  position: absolute;
                  top: -200px;
                  right: -105px;
                  z-index: -2;

                  @media (max-width:768px) {
                    width: 100%;
                    position: relative;
                    right: 0;
                    left: 0;
                    top: 0;
                  }
                }

                .anim-overlay {
                  display: flex;
                  flex-direction: column;
                  float: right;
                  align-items: flex-end;
                  justify-content: space-between;
                  min-height: 350px;
                  margin-right: -50px;
                  margin-top: -20px;

                  @media (max-width:768px) {
                    float: none;
                    align-items: center;
                    margin-right: 0;
                  }

                  img {
                    &:first-child {
                      width: 196px;

                      @media (max-width:768px) {
                        width: 100%;
                      }
                    }

                    &:last-child {
                      width: 171px;

                      @media (max-width:768px) {
                        width: 100%;
                      }
                    }
                  }
                }
              }

              .right-logo-partner-container {
                display: flex;
                justify-content: center;
                text-align: center;
                gap: 32px;
                padding-top: 32px;

                @media (max-width:450px) {
                  padding: 0;
                  display: none;
                }



                .website-builder-partner-image {
                  border: 1px solid;
                  border-color: transparent;
                  border-radius: 22px;
                  box-shadow: (4px 4px 12px rgba(0, 0, 0, 0.27));
                  padding: 16px 24px;
                  background-color: #FFFFFF;

                  img {
                    height: 64px;
                  }

                  @media (max-width:450px) {
                    width: 190px;

                  }
                }
              }
            }


          }
        }


      }

      .brandneed-container {

        .main-testimonials-container {
          width: 100%;
          position: relative;
          padding-top: 96px;

          @media (max-width:768px) {
            padding: 0;
          }

          .column-image-container {
            position: absolute;
            left: 31%;
            bottom: 75%;
          }



          .testimonials-conatiner-first,
          .testimonials-conatiner-second {
            display: flex;
            justify-content: space-between;
            gap: 15px;
            width: 100%;

            @media (max-width:768px) {
              display: block;

            }


            .coustmer-review-heading {
              font-weight: 700;
              font-size: 51px;
              line-height: 71px;
              color: #202020;
              padding-left: 18px;
              width: 50%;

              @media (max-width:768px) {
                font-size: 32px;
                line-height: 42px;
              }

              @media (max-width:768px) {
                width: 100%;
                font-size: 35px;
                padding-left: 14px;
              }

              .testimonials-heading {
                font-weight: 500;
                font-size: 24px;
                line-height: 49px;
                color: #0184FF;
                text-shadow: 2px 5px 40px rgba(0, 0, 0, 0.08);
              }

            }

            .couster-review-image-container {
              // display: flex;
              // justify-content: flex-end;
              // text-align: end;
              width: 50%;
              gap: 48px;
              display: grid;
              grid-template-columns: 1fr 1fr;

              @media (max-width:768px) {
                display: grid;
                grid-template-columns: 1fr;
                width: 100%;
                margin-top: 24px;
              }

              .first-review-image {
                background: url("../Assets/Images/kuldeep.png") no-repeat center;
              }

              .second-review-image {
                background: url("../Assets/Images/sumit.png") no-repeat center;
              }

              figure {
                height: 323px;
                overflow: hidden;
                cursor: pointer;
                position: relative;
                border-radius: 24px;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: inherit;
                }

                .divider {
                  width: 100px;
                  height: 1px;
                  border: 1px solid #FFFFFF;
                  text-align: center;
                }

                figcaption {
                  height: 100%;
                  width: 100%;
                  position: absolute;
                  top: 0;
                  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 67.36%, #282828 101.15%);
                }

                .figcaption-inner {
                  display: flex;
                  flex-direction: column;
                  position: absolute;
                  border-radius: inherit;
                  top: 0;
                  width: 100%;
                  text-align: left;
                  transform: translateY(75%);
                  transition: all 1s ease;
                  align-items: flex-start;
                  height: 100%;
                  padding: 24px;

                  p {
                    opacity: 0;
                    visibility: hidden;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 21px;
                    color: #FFFFFF;
                    display: -webkit-box;
                    -webkit-line-clamp: 8;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    margin-top: 32px;
                  }

                  h5 {
                    color: #FFFFFF;
                    font-weight: 500;
                    font-size: 19px;
                  }

                  h6 {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 19px;
                    color: #FFFFFF;
                  }
                }

                &:hover {
                  .figcaption-inner {
                    background-color: rgba(0, 0, 0, 0.6);
                    transform: translateY(0%);

                    p {
                      opacity: 1;
                      visibility: visible;
                    }
                  }
                }
              }




            }
          }

          .testimonials-conatiner-second {
            margin-top: 40px;

            .couster-review-image-container {
              width: 75%;
              margin-left: auto;
              margin-right: 0;
              grid-template-columns: 1fr 1fr 1fr;

              @media (max-width:768px) {
                grid-template-columns: 1fr;
                width: 100%;
                gap: 40px;
              }
            }
          }
        }
      }

      .main-form-container {
        width: 100%;
        position: relative;
        margin-top: 96px;

        .form-background {
          background: rgba(1, 132, 255, 0.1);
          border-top-right-radius: 16px;
          border-top-left-radius: 16px;
          width: 100%;
          height: auto;
          text-align: center;
          overflow: hidden;
          padding: 104px 0 0 0;

          .artwork-wrap {
            position: relative;

            @media (max-width:768px) {
              display: none;
            }
          }

          .artwork-outer {
            position: absolute;
            right: -120px;
            bottom: -130px;
            width: 250px;
            height: 250px;
            background: rgba(1, 132, 255, 0.2);
            border-radius: 50%;
          }

          .artwork-inner {
            position: absolute;
            right: -55px;
            bottom: -65px;
            width: 135px;
            height: 135px;
            background: #D3E7FA;
            border-radius: 50%;
          }

          .form-background-heading {
            font-weight: 700;
            font-size: 51px;
            line-height: 62px;
            padding: 0 0 20px 0;

            @media (max-width:768px) {
              font-size: 32px;
              line-height: 42px;
            }
          }

          .form-information {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: rgba(32, 32, 32, 0.6);
            padding-bottom: 220px;
            margin: auto;

            @media (max-width:768px) {
              padding-bottom: 160px;
              font-size: 16px;
              line-height: 24px;
            }
            @media (max-width:600px) {
              padding-bottom: 88px;
              font-size: 16px;
              line-height: 24px;
            }
          }

        }

        .form-container {
          background: #FFFFFF;
          display: flex;
          justify-content: space-between;
          // width: 78%;
          margin: auto;
          border: 1px solid #FFFFFF;
          padding: 36px;
          // width: 100%;
          border-radius: 28px;
          box-shadow: 6px 6px 20px 1px rgba(0, 0, 0, 0.1);
          margin-top: -15%;
          width: 90%;

          @media (max-width:768px) {
            width: 100%;
            flex-direction: column;
            gap: 30px;
            padding: 0;
          }

          .form-conatiner-left {
            background-color: #0184FF;
            color: #FFFFFF;
            padding: 24px 28px 36px 26px;
            width: 34%;
            border-radius: 24px;
            overflow: hidden;
            position: relative;

            .artwork-inner {
              position: absolute;
              right: -35px;
              top: -35px;
              width: 75px;
              height: 75px;
              background: rgba(211, 231, 250, 0.6);
              border-radius: 50%;
            }

            @media (max-width:768px) {
              width: 100%;
            }

            .form-container-heading {
              font-weight: 700;
              font-size: 24px;
              line-height: 29px;
              padding-right: 40px;

              @media (max-width:600px) {
                padding-right: 11px;
              }
            }

            .form-container-contact-info {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              padding-top: 22px;

            }

            .form-contact-number-container {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-top: 24px;
              gap: 18px;

              img {
                width: 16px;
              }

              a {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #FFFFFF;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }

              @media (max-width:768px) {
                word-break: break-all;
                align-items: flex-start;
              }
            }

            .form-mail-container {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-top: 24px;
              gap: 18px;

              img {
                width: 16px;
              }

              a {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #FFFFFF;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }

              @media (max-width:768px) {
                word-break: break-all;
                align-items: flex-start;
              }
            }

            .form-location-container {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-top: 24px;
              gap: 18px;

              img {
                width: 16px;
              }

              p {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #FFFFFF;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }

              @media (max-width:768px) {
                word-break: break-all;
                align-items: flex-start;
              }
            }


          }

          .form-conatiner-right {
            width: 61.5%;

            @media (max-width:768px) {
              width: 100%;
              padding: 16px 16px 32px 16px;
            }

            .personal-information-container {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 24px;

              @media (max-width:768px) {
                grid-template-columns: 1fr;
              }

              ::placeholder,
              textarea::placeholder {
                color: rgba(32, 32, 32, 0.6);
                font-weight: 400;
                font-size: 14px;
                text-transform: none !important;
              }

              :-ms-input-placeholder,
              textarea:-ms-input-placeholder {
                color: rgba(32, 32, 32, 0.6);
                font-weight: 400;
                font-size: 14px;
                text-transform: none !important;
              }

              ::-webkit-input-placeholder,
              textarea::-webkit-input-placeholder {
                color: rgba(32, 32, 32, 0.6);
                font-weight: 400;
                font-size: 14px;
                text-transform: none !important;
              }

              .form-input {
                &:nth-child(3) {
                  grid-column: 1/3;

                  @media (max-width:768px) {
                    grid-column: 1;
                  }
                }

                &:nth-child(4) {
                  grid-column: 1/3;

                  @media (max-width:768px) {
                    grid-column: 1;
                  }
                }

                small {
                  color: rgb(156, 2, 2);
                }

                .max-text {
                  color: rgba(32, 32, 32, 0.6);
                }
              }

              .input-container {
                border-radius: 4px;
                border: 1px solid rgba(32, 32, 32, 0.6);
                height: 40px;
                padding: 10px 20px;
                width: 100%;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                line-height: 20px;
                color: rgba(32, 32, 32, 0.6);
                outline: 0;
                outline: none;
                box-shadow: none;

                &:focus {
                  border-color: #0184FF;
                  border-width: 2px;
                }

                &.error {
                  border-color: rgb(156, 2, 2);
                }
              }

              textarea {
                &.input-container {
                  height: auto;
                }
              }

            }


            .form-send-message-button {
              padding: 14px 36px 14px 36px;
              background: #0184FF;
              border-radius: 8px;
              color: #FFFFFF;
              border: 1px solid #0184FF;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              margin-top: 13px;
            }
          }
        }
      }

      .about-us-container {
        padding: 96px 0;

        @media (max-width: 450px) {
          // margin-top: 654px;
        }

        .about-us-conatiner-first {

          width: 72%;

          @media (max-width:450px) {
            width: 100%;
          }


          .about-us-heading {
            font-weight: 500;
            font-size: 24px;
            line-height: 49px;
            text-shadow: 2px 5px 40px rgba(0, 0, 0, 0.08);
            color: #0184FF;
          }

          .about-us-subheading {
            font-weight: 700;
            font-size: 51px;
            line-height: 62px;
            color: #000000;

            @media (max-width:450px) {
              font-size: 23px;
              line-height: 30px;
            }
          }
        }

        .about-us-conatiner-second {
          display: flex;
          justify-content: space-between;
          padding-top: 42px;
          width: 100%;

          @media (max-width:768px) {
            flex-direction: column;
            gap: 10px;
          }

          .about-image-container {
            width: 50%;

            @media (max-width:768px) {
              width: 100%;
            }

            .about-us-image {
              max-width: 100%;
            }
          }

          .about-us-main-content-contaiiner {
            width: 53%;

            @media (max-width:768px) {
              width: 100%;
            }

            .about-us-content {
              font-weight: 400;
              font-size: 16px;
              line-height: 31px;
              width: 100%;
              padding: 0 0 0 72px;

              a {
                color: #0184FF;
              }

              @media (max-width:768px) {
                font-size: 16px;
                line-height: 22px;
                padding: 0;
              }
            }
          }

        }
      }


      .up-down {
        animation: up-down linear 4s;
        animation-iteration-count: infinite;
        transform-origin: 50% 50%;
        -webkit-animation: up-down linear 4s;
        -webkit-animation-iteration-count: infinite;
        -webkit-transform-origin: 50% 50%;
        -moz-animation: up-down linear 4s;
        -moz-animation-iteration-count: infinite;
        -moz-transform-origin: 50% 50%;
        -o-animation: up-down linear 4s;
        -o-animation-iteration-count: infinite;
        -o-transform-origin: 50% 50%;
        -ms-animation: up-down linear 4s;
        -ms-animation-iteration-count: infinite;
        -ms-transform-origin: 50% 50%;
      }

      @keyframes up-down {
        0% {
          transform: translate(1px, 20px);
        }

        24% {
          transform: translate(1px, 30px);
        }

        50% {
          transform: translate(1px, 12px);
        }

        74% {
          transform: translate(1px, 22px);
        }

        100% {
          transform: translate(1px, 22px);
        }
      }

      @-moz-keyframes up-down {
        0% {
          -moz-transform: translate(1px, 20px);
        }

        24% {
          -moz-transform: translate(1px, 30px);
        }

        50% {
          -moz-transform: translate(1px, 12px);
        }

        74% {
          -moz-transform: translate(1px, 22px);
        }

        100% {
          -moz-transform: translate(1px, 22px);
        }
      }

      @-webkit-keyframes up-down {
        0% {
          -webkit-transform: translate(1px, 20px);
        }

        24% {
          -webkit-transform: translate(1px, 30px);
        }

        50% {
          -webkit-transform: translate(1px, 12px);
        }

        74% {
          -webkit-transform: translate(1px, 22px);
        }

        100% {
          -webkit-transform: translate(1px, 22px);
        }
      }

      @-o-keyframes up-down {
        0% {
          -o-transform: translate(1px, 20px);
        }

        24% {
          -o-transform: translate(1px, 30px);
        }

        50% {
          -o-transform: translate(1px, 12px);
        }

        74% {
          -o-transform: translate(1px, 22px);
        }

        100% {
          -o-transform: translate(1px, 22px);
        }
      }

      @-ms-keyframes up-down {
        0% {
          -ms-transform: translate(1px, 20px);
        }

        24% {
          -ms-transform: translate(1px, 30px);
        }

        50% {
          -ms-transform: translate(1px, 12px);
        }

        74% {
          -ms-transform: translate(1px, 22px);
        }

        100% {
          -ms-transform: translate(1px, 22px);
        }
      }

      .testimonial-page, .contact-page, .service-page{
        
    padding-bottom: 96px;
  
      }
      .about-page{
        .about-us-container {
          padding: 32px 0;
      }
      }
      .service-page{
        .brandneed-functionality-container{
          padding: 48px 0;
      }
      }
      .contact-page{
        margin-top: 32px;
        .form-background{
          padding: 32px 0;
          background-color: transparent;
      }
      .artwork-wrap{
        display: none;
      }
      }