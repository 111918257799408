@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


$skyblue: #0184FF;



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

footer {
  width: 100%;
  display: flex;
  background: #093351;
  color: #ffff;
  padding: 48px 0;
.footer-grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.powered-by-divider{
  width: 100%;
  height: 1px;
  background-color: #9b9b9b;
  margin: 2rem 0 1rem 0;
}
.powered-by-section{
 p{
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
  text-align: center;
  a{
    color: #FFFFFF;
  }
 }
}
.brandneed-footer-info {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #FFFFFF;
  width: 60%;
  margin: 29px 0 45px 0;
  @media screen and (max-width: 768px) {
  width: 100%;
  margin: 29px 0;
  }
}


  .left-grid {
    height: auto;


   
    .footer-heading {
      display: flex;
      gap: 10px;
      align-items: center;
      @media screen and (max-width: 768px) {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        .heading-divider, .second-footer-heading{
          display: none;
        }
      }
      .first-footer-heading {
       font-family: 'Kanit';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;      
      color: #FFFFFF;
      margin-top: 18px;
      }

      .brandneed-logo {
        img{
          width: 56px;
        }
      }
    }

    .heading-divider {
      border: 1px solid #FFFFFF;
      width: 1px;
      height: 50px;
      margin: 0 15px 0 15px;
    }

    .second-footer-heading {

      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
     }

  
    .socialemedia-container{
      .socialemedia-head{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      }
    .socialemedia-item {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 16px;
      a{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid #FFF;
        padding: 8px;
        img{
          width: 100%;
          height: 100%;
          display: block;
          object-fit: contain;
        }
      }
    }
  }
  }

  .right-grid {
    @media screen and (max-width: 768px) {
      margin-top: 24px;
    }
   .menu-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    .footer-tran-heading {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      margin-bottom: 8px;

    }
    li{
      list-style-type: none;
    }
    .menu-item{
      cursor: pointer;
      padding: 6px 0;
      display: flex;
      align-items: flex-start;
      gap: 10px;
      i{
       display: flex;
       margin-top: 6px;
      }
      button, a, p{
        background-color: transparent;
        outline: none;
        border: none;
      text-decoration: none;
      color: #FFF;
      font-weight: 400;
font-size: 14px;
line-height: 22px;
cursor: pointer;
      &:hover{      
        color: rgb(233, 233, 233);
      }
      }
    }
    .menu-grid-list{
      &:last-child{
        a{
          display: flex;
          align-items: flex-start;
          gap: 10px;
        }
      }
    }
   }

 

    .brandneed-footer-form-heading {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #FFFFFF;
      margin-top: 62px;
    }

    .footer-form {
      padding: 14px 0px 19px 20px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      border-radius: 4px;
      border: 1px solid #FFFFFF;
      color: rgba(32, 32, 32, 0.6);
      margin-top: 16px;
      align-items: center;
      outline: none;
      width: 100%;

      

    }

    .footer-form-submit-btn {
      padding: 14px 28px 14px 28px;
      background: #343F64;
      border-radius: 8px;
      margin-top: 16px;
      color: #ffff;
      border: 1px solid #343F64;

      
    }

  }


}