@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$skyblue: #0184FF;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.head-top-wrap{
  z-index: 99;
  width: 100%;
  padding: 12px 0;
  background-color: #ecf1ff;
}
.upper-header {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
  flex-direction: column;
  }

  .upper-header-left-container {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    @media (max-width:768px) {
      justify-content: center;
    }
    .digital-markerting-agency {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      @media (max-width:768px) {
        display: none;  
      }
    }

    .call-us-link {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #0184FF;
      text-decoration: none;
      cursor: pointer;
      white-space: nowrap;
      &:hover{
        text-decoration: underline;
      }

    }
  }

  .upper-header-right-container {
    display: flex;
    gap: 36px;
    align-items: center;


    .mail-contact-conatiner {
      display: flex;
      align-items: center;
      gap: 10px;
      @media (max-width:768px) {
        display: none;  
      }

      .brandneed-mail {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #0184FF;
        align-items: center;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }

        @media (max-width:620px) {
          font-size: 16px;

        }

      }

      .mail-icon {
        img{
          width: 16px;
      margin-top: 4px;
         }
      }
    }

    .contact-no-conatiner {
      display: flex;
      align-items: center;
      gap: 10px;

      @media (max-width:768px) {
        display: none;

      }
      .contact-logo {
       img{
        width: 16px;
    margin-top: 4px;
       }
      }

      .brandneed-contact-no {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #0184FF;
        align-items: center;
text-decoration: none;
&:hover{
  text-decoration: underline;
}

      }
    }










  }
}
header{
  position: sticky;
  top: 0;
  background-color: #FFFFFF;
  z-index: 999;
}
.brandneed-header {
  box-shadow: rgba(0,0,0,0.1) 0px 10px 10px -10px;
 
  // @media (max-width:768px) {
  //   box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  // }
  transition:.6s ease;
  .Container{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  width: 100%;
  padding: 16px 0;

  // @media (max-width:960px) {
  //   width: 100%;
  // }



  // @media (max-width:760px) {
  //   width: 100%;

  // }

  .navbar-toggler {

    display: none;

    @media (max-width:768px) {
      display: block;
      width: 30px;
      height: 30px;
      text-align: center;
      padding: 5px;
      border-color: transparent;
      background: white;
      display: flex;
      flex-direction: column;

    }

    .navbar-toggler-icon {
      height: 1px;
      width: 100%;
      color: #222;
      font-size: 25px;
      border: 1px solid black;
      margin: 3px;
    }
  }


  .brandneed-logo {
    display: flex;
    align-items: flex-end;
   gap: 10px;
   text-decoration: none;
cursor: pointer;
    .logo-pic {
      text-align: center;
      align-items: center;
      width: 56px;
    }

    .logo-text {
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;      
      color: #202020;
    }
  }





  .header-component-hide {
    display: flex;
    flex-basis: auto;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #202020;
    text-align: center;
    @media (max-width:768px) {
      display: block;
      flex-basis: 100%;
      flex-grow: 1;
      align-items: center;
      padding: 2rem 0;
    }
    ul{
      display: flex;
      flex-direction: row;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      gap: 48px;
      align-items: center; 
      @media (max-width:768px) {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        gap:24px;
        align-items: flex-start;
      }
      li{
        list-style-type: none;
      }
    }
    @media (max-width:768px) {
      &:not(.header-component-show) {
        display: none;
    }
      }
   
   
    // @media (max-width:960px) {
    //   width: 75%;
    //   margin-right: 0px;
    // }


    // @media (max-width:760px) {
    //   position: fixed;
    //   width: 100%;
    //   top: 162px;
    //   left: -100%;
    //   height: 10vh;
    //   width: 100%;
    //   color: #202020;
    //   display: block;
    //   transition: all 0.5s ease;

    // }

    // #co-header-first-component {
    //   @media (max-width:760px) {
    //     margin-top: 80px;
    //   }

    //   @media (max-width:450px) {
    //     margin-top: 106px;
    //   }
    // }



    .co-header-component {
     a{
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #202020;
      cursor: pointer;
      text-decoration: none;
      transition-duration:500ms;
      &:hover{
        color: #0184FF;
      }
     }

      // @media (max-width:760px) {
      //   margin: 27px 0 0 10px;
      //   color: #202020;
      // }

    }

// &.header-component-show {
//   display: block;
//  }
  }

 
  }
}