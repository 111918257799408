.Container{
width: 100%;
max-width: 1320px;
margin-right: auto;
margin-left: auto;
padding-right: .75rem;
padding-left: .75rem;
@media screen and (max-width: 1440px) {
  max-width: 90%;
}
@media screen and (max-width: 1200px) {
  max-width: 90%;
}
@media screen and (max-width: 992px) {
  max-width: 90%;
}
@media screen and (max-width: 768px) {
  max-width: 90%;

}
@media screen and (max-width: 576px) {
  max-width: 90%;

}
}
.Container-fluid{
width: 100%;
margin-right: auto;
margin-left: auto;
padding-right: .75rem;
padding-left: .75rem;
}